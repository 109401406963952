import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        (!props.isPrivate || props.authentication.loggedIn ? (
          props.roleId && props.roleId !== props.authentication.roleId ?
          <Redirect to={{ pathname: '/' }} /> :
          <Layout title={props.title}>
            <Component {...matchProps} />
          </Layout>) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ))
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  path: PropTypes.string,
  title: PropTypes.string
};

const mapStateToProps = state => ({
  authentication: state.authentication,
})

export default connect(mapStateToProps)(RouteWithLayout);