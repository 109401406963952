import { get, post, put, doDelete } from './index';

export const users = () => {
    return get("api/user");
}

export const user = (id) => {
    return get("api/user/" + id);
}

export const addUser = (user) => {
    return post("api/user", user);
}

export const udpateUser = (id, user) => {
    return put("api/user/" + id, user);
}

export const deleteUser = (id) => {
    return doDelete("api/user/" + id);
}

export const password = (id, password) => {
    const data = {
        'new_password': password
    };
    return put("api/user/password/" + id, data);
}
