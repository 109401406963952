import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main } from './layout';
import {
  SignIn,
  NotFound,
  Home,
  ChartList,
  ChartView,
  ClientList,
  ClientView,
  UserList,
  UserView,
  UserResetPassword,
  ReportView,
  Account
} from './pages';
import { ROLE_ADMIN, ROLE_USER } from './constants/role';

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Home}
        exact
        layout={Main}
        path="/"
        title="Home"
        isPrivate={true}
      />
      <RouteWithLayout
        component={Account}
        exact
        layout={Main}
        path="/account"
        title="Mi cuenta"
        isPrivate={true}
      />
      <RouteWithLayout
        component={ChartList}
        exact
        layout={Main}
        path="/charts"
        title="Charts"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={ChartView}
        layout={Main}
        path="/chart/:id?"
        title="Charts"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={ClientList}
        exact
        layout={Main}
        path="/clients"
        title="Clientes"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={ClientView}
        layout={Main}
        path="/client/:id?"
        title="Clientes"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={UserList}
        exact
        layout={Main}
        path="/users"
        title="Usuarios"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={UserResetPassword}
        layout={Main}
        path="/user/password/:id?"
        title="Resetear contraseña"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={UserView}
        layout={Main}
        path="/user/:id?"
        title="Usuarios"
        isPrivate={true}
        roleId={ROLE_ADMIN}
      />
      <RouteWithLayout
        component={ReportView}
        layout={Main}
        path="/report/:id?"
        title="Dashboard"
        isPrivate={true}
        roleId={ROLE_USER}
      />
      <Route
        component={SignIn}
        exact
        path="/login"
      />
      <Route
        component={NotFound}
        exact
        path="*"
      />
    </Switch>
  );
};

export default Routes;
