import { get, post, put, doDelete } from './index';

export const charts = () => {
    return get("api/chart");
}

export const chart = (id) => {
    return get("api/chart/" + id);
}

export const addChart = (chart) => {
    return post("api/chart", chart);
}

export const udpateChart = (id, chart) => {
    return put("api/chart/" + id, chart);
}

export const deleteChart = (id) => {
    return doDelete("api/chart/" + id);
}
