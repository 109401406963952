import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import history from '../../helpers/history';
import { ErrorAlert, Progress } from '../../components';
import { useParams } from "react-router-dom";
import { charts as apiCharts } from '../../api/chart';
import { client as apiClient, addClient as apiAddClient, udpateClient as apiUpdateClient } from '../../api/client';
import { connect } from 'react-redux';
import { setSuccess } from '../../actions/success';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginRight: theme.spacing(2)
    },
    cancel: {
        margin: theme.spacing(3, 0, 2),
      },
  }));

const View = (props) => {

    const classes = useStyles();

    const [name, setName] = useState("");
    const [charts, setCharts] = useState([]);
    const [selectedCharts, setSelectedCharts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const getCharts = async () => {
            const response = await apiCharts();
            if (response.status === 200) {
                setCharts(response.data);
                if (id)
                    getClient();
            }
            else {
                setErrors(response.data);
            }
        }
        const getClient = async () => {
            setLoading(true);
            const response = await apiClient(id);
            if (response.status === 200) {
                setName(response.data.name);
                setSelectedCharts(response.data.charts.map(chart => {
                    return chart.id;
                }));
            }
            else {
                setErrors(response.data);
            }
            setLoading(false);
        }
        getCharts();
    }, []);

    const handleChange = event => {
        switch (event.target.name) {
          case "name":
            setName(event.target.value);
            break;
        }
    }

    const selectChart = (id) => {
        const index = selectedCharts.indexOf(id);
        if (index === -1)
            setSelectedCharts(selectedCharts.concat([id]));
        else {
            selectedCharts.splice(index, 1);
            setSelectedCharts([ ...selectedCharts ]);
        }
    }

    const goTo = (url) => {
        history.push(url);
    }

    const isFormValid = () => {
        return name.trim().length > 0;
    }

    const onSave = async () => {
        const client = {
            name: name,
            charts: selectedCharts
        };
        setLoading(true);
        const response = id ? await apiUpdateClient(id, client) : await apiAddClient(client);
        if (response.status === 200) {
            props.setSuccess("Se ha guardado el client correctamente.");
            goTo("/clients");
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            {loading && <Progress />}
            {errors && <ErrorAlert errors={errors} />}
            <Paper>
            <div className={classes.paper}>
                <form className={classes.form} noValidate autoComplete="off">
                    <Typography variant="h6" component="h6">{(id ? "Editar" : "Crear") + " cliente"}</Typography>
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Nombre"
                        name="name"
                        autoFocus
                        helperText=""
                        value={name}
                        onChange={handleChange}
                    />
                    <Typography>Charts</Typography>
                    <FormGroup>
                        {charts.map(chart => (
                        <FormControlLabel
                            control={
                            <Checkbox
                                onClick={() => selectChart(chart.id)}
                                checked={selectedCharts.indexOf(chart.id) !== -1}
                                name="check"
                                color="primary"
                            />
                            }
                            label={chart.title}
                        />))}
                    </FormGroup>
                    <Button
                        onClick={() => onSave()}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!isFormValid()}
                    >
                        Guardar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => goTo("/clients")}
                        color="error"
                        className={classes.submit}
                    >
                        Cancelar
                    </Button>
                </form>
            </div>
            </Paper>
        </Container>
    )
}

const mapDispatchToProps = dispatch => {
    return {
      setSuccess: (success) => dispatch(setSuccess(success))
    };
}

export default connect(null, mapDispatchToProps)(View);
