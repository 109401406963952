import React, { useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import history from '../helpers/history';
import { ROLE_ADMIN, ROLE_USER } from '../constants/role';
import { reports as getReports } from '../actions/report';

const goTo = (url) => {
  history.push(url);
}

const adminListItems = (
  <div>
    <ListItem button onClick={() => goTo("/clients")}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Clientes" />
    </ListItem>
    <ListItem button onClick={() => goTo("/charts")}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Charts" />
    </ListItem>
    <ListItem button onClick={() => goTo("/users")}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Usuarios" />
    </ListItem>
  </div>
);

const userListItems = (reports) => {
  if (reports)
    return (
      <div>
        {reports.map(report => (
          <ListItem key={report.id} button component="a" href={"/report/" + report.id}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={report.title} />
        </ListItem>
        ))}
      </div>
    );
  return null;
}

export const ListItems = (props) => {
  
  useEffect(() => {
    if (props.roleId === ROLE_USER && !props.reports) {
      props.getReports();
    }
  }, []);

  return props.roleId === ROLE_ADMIN ? adminListItems : userListItems(props.reports);
}

const mapStateToProps = state => ({
  roleId: state.authentication.roleId,
  reports: state.report.reports
});

const mapDispatchToProps = dispatch => {
  return {
    getReports: () => dispatch(getReports()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListItems);