import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { clients as apiClients, deleteClient as apiDeleteClient } from '../../api/client';
import { ErrorAlert, Progress, Confirm, Snack } from '../../components';
import history from '../../helpers/history';
import { connect } from 'react-redux';
import { setSuccess } from '../../actions/success';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  media: {
    textAlign: "center",
    paddingTop: 25
  },
  table: {
    minWidth: 650,
  },
  fabAdd: {
    marginBottom: theme.spacing(2),
  },
  fabEdit: {
    marginRight: theme.spacing(2),
  },
}));

const List = (props) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState(null);
  const [errors, setErrors] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [snackMessage, setSnackMessage] = useState(props.success);

  useEffect(() => {
    const getClients = async () => {
        const response = await apiClients();
        if (response.status === 200) {
            setClients(response.data);
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }
    if (props.success)
      props.setSuccess(null);
    getClients();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const goTo = (url) => {
    history.push(url);
  }

  const onDeleteConfirm = (confirm) => {
    if (confirm) {
      apiDeleteClient(clientToDelete.id).then(response => {
        if (response.status === 200) {
          apiClients().then(response => {
            if (response.status === 200) {
              setClients(response.data);
            }
            else
              setErrors(response.data);
            setSnackMessage("Se ha eliminado el cliente correctamente.");
          });
        }
        else
          setErrors(response.data);
        });
    }
    setClientToDelete(null);
  }

  if (loading)
        return <Progress />
  if (errors)
      return <ErrorAlert errors={errors} />

  return (
    <Fragment>
        <Grid container justify="flex-end">
            <Tooltip title="Agregar cliente" aria-label="add">
                <Fab onClick={() => goTo("/client")} color="primary" size="small" className={classes.fabAdd}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </Grid>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Acciones</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {clients.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((client, index) => (
                    <TableRow key={index}>
                    <TableCell>{client.name}</TableCell>
                    <TableCell>
                        <Tooltip title="Editar cliente" aria-label="add" className={classes.fabEdit}>
                            <Fab onClick={() => goTo("/client/" + client.id)} color="primary" size="small" className={classes.fab}>
                                <EditIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Eliminar cliente" aria-label="add">
                            <Fab color="inherit" size="small" className={classes.fab} onClick={() => setClientToDelete(client)}>
                                <DeleteIcon />
                            </Fab>
                        </Tooltip>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={clients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
        <Confirm
          open={clientToDelete ? true : false}
          title="Eliminar cliente"
          body="¿Está seguro que desea eliminar el cliente?"
          onConfirm={(confirm) => onDeleteConfirm(confirm)}
        />
        <Snack
          open={snackMessage ? true : false}
          message={snackMessage}
          onClose={() => setSnackMessage(null)}
        />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  success: state.success.message
});

const mapDispatchToProps = dispatch => {
  return {
    setSuccess: (success) => dispatch(setSuccess(success))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
