import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { users as apiUsers, deleteUser as apiDeleteUser } from '../../api/user';
import { ErrorAlert, Progress, Confirm, Snack } from '../../components';
import history from '../../helpers/history';
import { connect } from 'react-redux';
import { setSuccess } from '../../actions/success';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  media: {
    textAlign: "center",
    paddingTop: 25
  },
  table: {
    minWidth: 650,
  },
  fabAdd: {
    marginBottom: theme.spacing(2),
  },
  fabEdit: {
    marginRight: theme.spacing(2),
  },
}));

const List = (props) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [errors, setErrors] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [snackMessage, setSnackMessage] = useState(props.success);

  useEffect(() => {
    const getUsers = async () => {
        const response = await apiUsers();
        if (response.status === 200) {
            setUsers(response.data);
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }
    if (props.success)
      props.setSuccess(null);
    getUsers();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const goTo = (url) => {
    history.push(url);
  }

  const onDeleteConfirm = (confirm) => {
    if (confirm) {
      apiDeleteUser(userToDelete.id).then(response => {
        if (response.status === 200) {
          apiUsers().then(response => {
            if (response.status === 200) {
              setUsers(response.data);
            }
            else
              setErrors(response.data);
            setSnackMessage("Se ha eliminado el usuario correctamente.");
          });
        }
        else
          setErrors(response.data);
        });
    }
    setUserToDelete(null);
  }

  if (loading)
        return <Progress />
  if (errors)
      return <ErrorAlert errors={errors} />

  return (
    <Fragment>
        <Grid container justify="flex-end">
            <Tooltip title="Agregar usuario" aria-label="add">
                <Fab onClick={() => goTo("/user")} color="primary" size="small" className={classes.fabAdd}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </Grid>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Apellido</TableCell>
                    <TableCell>Rol</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Acciones</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {users.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((user, index) => (
                    <TableRow key={index}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.firstname}</TableCell>
                    <TableCell>{user.lastname}</TableCell>
                    <TableCell>{user.role.name}</TableCell>
                    <TableCell>{user.client ? user.client.name : ""}</TableCell>
                    <TableCell>
                        <Tooltip title="Editar usuario" aria-label="add" className={classes.fabEdit}>
                            <Fab onClick={() => goTo("/user/" + user.id)} color="primary" size="small" className={classes.fab}>
                                <EditIcon />
                            </Fab>
                        </Tooltip>
                        {user.id !== props.userId && <Tooltip title="Resetear contraseña" aria-label="add" className={classes.fabEdit}>
                            <Fab size="small" className={classes.fab} color="primary" onClick={() => goTo("/user/password/" + user.id)}>
                                <LockIcon />
                            </Fab>
                        </Tooltip>}
                        {user.id !== props.userId && <Tooltip title="Eliminar usuario" aria-label="add">
                            <Fab color="inherit" size="small" className={classes.fab} onClick={() => setUserToDelete(user)}>
                                <DeleteIcon />
                            </Fab>
                        </Tooltip>}
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
        <Confirm
          open={userToDelete ? true : false}
          title="Eliminar usuario"
          body="¿Está seguro que desea eliminar el usuario?"
          onConfirm={(confirm) => onDeleteConfirm(confirm)}
        />
        <Snack
          open={snackMessage ? true : false}
          message={snackMessage}
          onClose={() => setSnackMessage(null)}
        />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  success: state.success.message,
  userId: state.authentication.id,
});

const mapDispatchToProps = dispatch => {
  return {
    setSuccess: (success) => dispatch(setSuccess(success))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
