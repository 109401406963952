import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    copyrightText: {
      fontSize: "12px",
      fontStyle: "italic",
      marginTop: "10px",
      textAlign: "justify"
    }
  }));

const Copyright = () => {

    const classes = useStyles();

    return (
        <Typography variant="body2" color="textSecondary" align="left">
            {'© Copyright ' + new Date().getFullYear() + ' '}
            <Link color="inherit" href="https://agroview.net">
                agroview.net
            </Link>
            <div className={classes.copyrightText}>
                <div>Toda la información suministrada por el presente servicio no deberá ser comercializada, reproducida, publicada, ni cedida; total ni parcialmente, sin expresa autorización.</div>
                <div>Los Reportes agroview no constituyen ninguna recomendación; sino que brindan información actualizada que se ha creado, obtenido y procesado dando cumplimiento a las normas de protección de datos personales y demás normativas aplicables, para que el cliente disponga de herramientas adicionales para sus propias decisiones vinculadas a sus actividades de agricultura, ganadería, lechera, forestal, agrícola intensiva de cualquier tipo y/o actividades afines.</div>
            </div>
        </Typography>)
}

export default Copyright;