import { get, post, put, doDelete } from './index';

export const clients = () => {
    return get("api/client");
}

export const client = (id) => {
    return get("api/client/" + id);
}

export const addClient = (client) => {
    return post("api/client", client);
}

export const udpateClient = (id, client) => {
    return put("api/client/" + id, client);
}

export const deleteClient = (id) => {
    return doDelete("api/client/" + id);
}
