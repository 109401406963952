import { get, post, put } from './index';

export const login = (email, password) => {
    const data = {
        'email': email,
        'password': password
    };
    return post("api/auth/token", data, false);
}

export const user = () => {
    return get("api/user/current");
}

export const password = (current, password) => {
    const data = {
        'current_password': current,
        'new_password': password
    };
    return put("api/user/current/password", data);
}
