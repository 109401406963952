import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import history from '../../helpers/history';
import { ErrorAlert, Progress } from '../../components';
import { useParams } from "react-router-dom";
import { user as apiUser, addUser as apiAddUser, udpateUser as apiUpdateUser } from '../../api/user';
import { clients as apiClients } from '../../api/client';
import { connect } from 'react-redux';
import { setSuccess } from '../../actions/success';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { ROLE_USER, ROLE_ADMIN } from '../../constants/role';
import { validate as emailValid } from '../../helpers/email';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginRight: theme.spacing(2)
    },
    cancel: {
        margin: theme.spacing(3, 0, 2),
    },
    role: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }
  }));

const View = (props) => {

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [roleId, setRoleId] = useState(ROLE_USER);
    const [clientId, setClientId] = useState("");
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const getClients = async () => {
            const response = await apiClients();
            if (response.status === 200) {
                setClients(response.data);
                if (id)
                    getUser();
            }
            else {
                setErrors(response.data);
            }
        }
        const getUser = async () => {
            setLoading(true);
            const response = await apiUser(id);
            if (response.status === 200) {
                setEmail(response.data.email);
                setFirstname(response.data.firstname);
                setLastname(response.data.lastname);
                setRoleId(response.data.role_id);
                setClientId(response.data.client_id);
            }
            else {
                setErrors(response.data);
            }
            setLoading(false);
        }
        getClients();
    }, []);

    const handleChange = (name, value) => {
        switch (name) {
          case "email":
            setEmail(value);
            break;
          case "password":
            setPassword(value);
            break;
          case "confirmPassword":
            setConfirmPassword(value);
            break;
        case "firstname":
            setFirstname(value);
            break;
        case "lastname":
            setLastname(value);
            break;
        case "roleId":
            setRoleId(value);
            break;
        case "clientId":
            setClientId(value);
            break;
        }
    }

    const goTo = (url) => {
        history.push(url);
    }

    const isFormValid = () => {
        if (id)
            return firstname.length > 0
                && lastname.length > 0
                && (roleId === ROLE_ADMIN || clientId);
        else
            return emailValid(email)
                && password.length > 0
                && confirmPassword.length > 0
                && password === confirmPassword
                && firstname.length > 0
                && lastname.length > 0
                && (roleId === ROLE_ADMIN || clientId);
    }

    const onSave = async () => {
        const user = {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            role_id: roleId,
            client_id: clientId
        };
        setLoading(true);
        const response = id ? await apiUpdateUser(id, user) : await apiAddUser(user);
        if (response.status === 200) {
            props.setSuccess("Se ha guardado el usuario correctamente.");
            goTo("/users");
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            {loading && <Progress />}
            {errors && <ErrorAlert errors={errors} />}
            <Paper>
            <div className={classes.paper}>
                <form className={classes.form} noValidate autoComplete="off">
                    <Typography variant="h6" component="h6">{(id ? "Editar" : "Crear") + " usuario"}</Typography>
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoFocus
                        helperText=""
                        disabled={id ? true : false}
                        value={email}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    {!id && <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="password"
                        label="Contraseña"
                        name="password"
                        helperText=""
                        value={password}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />}
                    {!id && 
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="confirmPassword"
                        label="Confirme contraseña"
                        name="confirmPassword"
                        helperText=""
                        value={confirmPassword}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />}
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstname"
                        label="Nombre"
                        name="firstname"
                        helperText=""
                        value={firstname}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lastname"
                        label="Apellido"
                        name="lastname"
                        helperText=""
                        value={lastname}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <RadioGroup id="roleId" className={classes.role} value={roleId} onChange={(event) => handleChange("roleId", parseInt(event.target.value))} row aria-label="roleId" name="roleId">
                        <FormControlLabel value={ROLE_USER} control={<Radio color="primary" disabled={id ? true : false} />} label="Usuario" />
                        <FormControlLabel value={ROLE_ADMIN} control={<Radio color="primary" disabled={id ? true : false}  />} label="Administrador" />
                    </RadioGroup>
                    {roleId === ROLE_USER && <FormControl variant="outlined" fullWidth>
                        <InputLabel id="client-label">Cliente</InputLabel>
                        <Select
                            labelId="client-label"
                            id="clientId"
                            label="Cliente"
                            value={clientId}
                            onChange={(event) => handleChange("clientId", event.target.value)}
                        >
                            <MenuItem value="">
                                <em>Seleccione</em>
                            </MenuItem>
                            {clients.map(client => (
                                <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                    <Button
                        onClick={() => onSave()}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!isFormValid()}
                    >
                        Guardar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => goTo("/users")}
                        color="error"
                        className={classes.submit}
                    >
                        Cancelar
                    </Button>
                </form>
            </div>
            </Paper>
        </Container>
    )
}

const mapDispatchToProps = dispatch => {
    return {
      setSuccess: (success) => dispatch(setSuccess(success))
    };
}

export default connect(null, mapDispatchToProps)(View);
