import { combineReducers } from 'redux';
import authentication from './authentication';
import report from './report';
import success from './success';

const rootReducer = combineReducers({
  authentication,
  report,
  success
});

export default rootReducer;