import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import history from '../../helpers/history';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { setSuccess } from '../../actions/success';
import { ErrorAlert, Progress } from '../../components';
import { user as apiUser, password as apiPassword } from '../../api/user';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginRight: theme.spacing(2)
    },
    cancel: {
        margin: theme.spacing(3, 0, 2),
    },
    role: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }
  }));

const ResetPassword = (props) => {

    const classes = useStyles();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            const response = await apiUser(id);
            if (response.status === 200) {
                setFirstname(response.data.firstname);
                setLastname(response.data.lastname);
            }
            else {
                setErrors(response.data);
            }
            setLoading(false);
        }
        getUser();
    }, []);

    const handleChange = (name, value) => {
        switch (name) {
          case "password":
            setPassword(value);
            break;
          case "confirmPassword":
            setConfirmPassword(value);
            break;
        }
    }

    const goTo = (url) => {
        history.push(url);
    }

    const isFormValid = () => {
        return password.length > 0
            && confirmPassword.length > 0
            && password === confirmPassword;
    }

    const onSave = async () => {
        setLoading(true);
        const response = await apiPassword(id, password);
        if (response.status === 200) {
            props.setSuccess("Se ha reseteado la contraseña correctamente.");
            goTo("/users");
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            {loading && <Progress />}
            {errors && <ErrorAlert errors={errors} />}
            <Paper>
            <div className={classes.paper}>
                <form className={classes.form} noValidate autoComplete="off">
                    <Typography variant="h6" component="h6">Resetear contraseña</Typography>
                    <Typography>{firstname + " " + lastname}</Typography>
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="password"
                        label="Nueva contraseña"
                        name="password"
                        helperText=""
                        value={password}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="confirmPassword"
                        label="Confirme contraseña"
                        name="confirmPassword"
                        helperText=""
                        value={confirmPassword}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <Button
                        onClick={() => onSave()}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!isFormValid()}
                    >
                        Guardar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => goTo("/users")}
                        color="error"
                        className={classes.submit}
                    >
                        Cancelar
                    </Button>
                </form>
            </div>
            </Paper>
        </Container>
    )
}

const mapDispatchToProps = dispatch => {
    return {
      setSuccess: (success) => dispatch(setSuccess(success))
    };
}

export default connect(null, mapDispatchToProps)(ResetPassword);
