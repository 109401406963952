import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import history from '../../helpers/history';
import { ErrorAlert, Progress } from '../../components';
import { useParams } from "react-router-dom";
import { chart as apiChart, addChart as apiAddChart, udpateChart as apiUpdateChart } from '../../api/chart';
import moment from 'moment';
import { connect } from 'react-redux';
import { setSuccess } from '../../actions/success';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginRight: theme.spacing(2)
    },
    cancel: {
        margin: theme.spacing(3, 0, 2),
      },
  }));

const View = (props) => {

    const classes = useStyles();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [dashboardId, setDashboardId] = useState("");
    const [lastUpdatedAt, setLastUpdatedAt] = useState(moment().format("YYYY-MM-DDTHH:mm"));
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const getChart = async () => {
            setLoading(true);
            const response = await apiChart(id);
            if (response.status === 200) {
                setTitle(response.data.title);
                setDescription(response.data.description);
                setDashboardId(response.data.dashboard_id);
                setLastUpdatedAt(moment.utc(response.data.last_updated_at).format("YYYY-MM-DDTHH:mm"));
            }
            else {
                setErrors(response.data);
            }
            setLoading(false);
        }
        if (id)
            getChart();
    }, []);

    const handleChange = event => {
        switch (event.target.name) {
          case "title":
            setTitle(event.target.value);
            break;
          case "description":
            setDescription(event.target.value);
            break;
          case "dashboardId":
            setDashboardId(event.target.value);
            break;
          default:
            setLastUpdatedAt(event.target.value);
            break;
        }
    }

    const goTo = (url) => {
        history.push(url);
    }

    const isFormValid = () => {
        return title.trim().length > 0
            && description.trim().length > 0
            && dashboardId.trim().length > 0
            && moment(lastUpdatedAt).isValid();
    }

    const onSave = async () => {
        const chart = {
            title: title,
            description: description,
            dashboard_id: dashboardId,
            last_updated_at: lastUpdatedAt
        };
        setLoading(true);
        const response = id ? await apiUpdateChart(id, chart) : await apiAddChart(chart);
        if (response.status === 200) {
            props.setSuccess("Se ha guardado el chart correctamente.");
            goTo("/charts");
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            {loading && <Progress />}
            {errors && <ErrorAlert errors={errors} />}
            <Paper>
            <div className={classes.paper}>
                <form className={classes.form} noValidate autoComplete="off">
                    <Typography variant="h6" component="h6">{(id ? "Editar" : "Crear") + " chart"}</Typography>
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Título"
                        name="title"
                        autoFocus
                        helperText=""
                        value={title}
                        onChange={handleChange}
                    />
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="description"
                        label="Descripción"
                        id="description"
                        helperText=""
                        value={description}
                        onChange={handleChange}
                    />
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="dashboardId"
                        label="Dashboard ID"
                        id="dashboardId"
                        helperText=""
                        value={dashboardId}
                        onChange={handleChange}
                    />
                    <TextField
                        error={false}
                        id="lastUpdatedAt"
                        label="Última actualización *"
                        type="datetime-local"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText=""
                        value={lastUpdatedAt}
                        onChange={handleChange}
                    />
                    <Button
                        onClick={() => onSave()}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!isFormValid()}
                    >
                        Guardar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => goTo("/charts")}
                        color="error"
                        className={classes.submit}
                    >
                        Cancelar
                    </Button>
                </form>
            </div>
            </Paper>
        </Container>
    )
}

const mapDispatchToProps = dispatch => {
    return {
      setSuccess: (success) => dispatch(setSuccess(success))
    };
}

export default connect(null, mapDispatchToProps)(View);
