import {
    SUCCESS
  } from '../constants/success';
  
  const initialState = {
      message: null
  };
  
  export default function success(state = initialState, action) {
    switch (action.type) {
      case SUCCESS:
          return {
            message: action.payload
          };
      default:
        return state;
    }
  }