import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const Confirm = props => {
    
    const onConfirm = (confirm) => {
        props.onConfirm(confirm);
    }
    
    return (<Dialog
        open={props.open}
        onClose={() => onConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.body}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => onConfirm(false)} color="primary">
            Cancelar
        </Button>
        <Button onClick={() => onConfirm(true)} color="primary" autoFocus>
            Aceptar
        </Button>
        </DialogActions>
    </Dialog>);
}

export default Confirm;