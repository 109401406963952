import { reports as apiReports } from "../api/report";
import { REPORTS_SUCCESS, REPORTS_FAILURE } from '../constants/report';

export const reports = () => async (dispatch) => {
  
    apiReports()
        .then(response => {
            if (response.status === 200) {
                dispatch({ type: REPORTS_SUCCESS, payload: response.data });
            }
            else {
                dispatch({ type: REPORTS_FAILURE });
            }
    });
    
}

export const clearReports = () => async (dispatch) => {
    dispatch({ type: REPORTS_SUCCESS, payload: null });
}
