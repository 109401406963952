import config from '../config.json';
import { STORAGE_USER } from '../constants/authentication';
const axios = require('axios');

const instance = axios.create({
    baseURL: config.apiUrl,
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 401;
    },
});

const makeResponse = (status, data) => {
    return { status: status, data: data };
}

const makeRequest = async (url, method, data, mustAuthorize, mustRefreshToken = true) => {

    let user = null;
    if (mustAuthorize) {
        const storageUser = localStorage.getItem(STORAGE_USER);
        user = storageUser ? JSON.parse(storageUser) : null;
        if (!user)
            return makeResponse(401, "No se encuentra autorizado para realizar esta operación. Por favor, vuelva a iniciar sesión.");
        instance.defaults.headers.common["Authorization"] = "Bearer " + user.token;
    }
    
    return instance.request({
        url: url,
        method: method,
        data: data
    })
    .then((response) => {
        if (response.status === 401) {
            // Try refresh token
            if (mustAuthorize && mustRefreshToken) {
                instance.defaults.headers.common["Authorization"] = "Bearer " + user.refreshToken;
                return makeRequest('api/auth/refresh-token', 'get', null, false, false)
                    .then((response) => {
                        if (response.status === 200) {
                            // Store access tokens
                            user.token = response.data.token;
                            user.refreshToken = response.data.refresh_token;
                            localStorage.setItem(STORAGE_USER, JSON.stringify(user));
                            // Make original request again
                            return makeRequest(url, method, data, mustAuthorize);
                        }
                        else {
                            return makeResponse(response.status, "No se encuentra autorizado para realizar esta operación. Por favor, vuelva a iniciar sesión.");
                        }
                    });
            }
            else {
                // Return 401
                return makeResponse(response.status, "No se encuentra autorizado para realizar esta operación. Por favor, vuelva a iniciar sesión.");
            }
        }
        else {
            return makeResponse(response.status, response.data);
        }
    })
    .catch((error) => {
        console.log(error);
        return makeResponse(error.response ? error.response.status : 400, error.response ? error.response.data.message : "Ha ocurrido un error. Por favor inténtelo de nuevo.");
    });
}

export const get = (url, mustAuthorize = true) => {
    return makeRequest(url, 'get', null, mustAuthorize);
}

export const post = (url, data, mustAuthorize = true) => {
    return makeRequest(url, 'post', data, mustAuthorize);
}

export const put = (url, data, mustAuthorize = true) => {
    return makeRequest(url, 'put', data, mustAuthorize);
}

export const doDelete = (url, mustAuthorize = true) => {
    return makeRequest(url, 'delete', null, mustAuthorize);
}