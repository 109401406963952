import {
    REPORTS_SUCCESS,
    REPORTS_FAILURE
  } from '../constants/report';
  
  const initialState = {
      reports: null
  };
  
  export default function report(state = initialState, action) {
    switch (action.type) {
      case REPORTS_SUCCESS:
          return {
              reports: action.payload
          };
      case REPORTS_FAILURE:
        return {
            reports: []
        };
      default:
        return state;
    }
  }