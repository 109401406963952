import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

const useStyles = makeStyles({
    description: {
        marginTop: 10,
        marginBottom: 10
    },
});

const Report = (props) => {
    const classes = useStyles();
    const dashboardRef = React.createRef();

    const embed = () => {
        const options = {
            url: props.report.url,
            container: dashboardRef.current,
            scrolling: "no",
            height: "700px",
            width: "1200px"
        };
        embedDashboard(options);
    }

    useEffect(() => {
        embed();
    });

    return (
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
                {props.report.title}
                </Typography>
                <Typography className={classes.description} color="textSecondary">
                    {props.report.description}
                </Typography>
            </CardContent>
            <div ref={dashboardRef} />
        </Card>
      );
}

export default Report;