import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4)
  },
  paper: {
    // padding: theme.spacing(4),
    textAlign: "center"
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h6" component="h6">Bienvenido al portal de clientes de agroview.net!</Typography>
      </Paper>
    </div>
  );
};

export default Home;
