import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Copyright from '../../layout/Copyright';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { login } from '../../actions/authentication';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Progress } from '../../components';

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: {
      borderTop: '4px solid #00de00',
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    margin: theme.spacing(1),
    backgroundImage: `url(images/logo.png)`,
    width: 350,
    backgroundPosition: 'center',
    height: 100,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#00de00'
  },
}));

const SignIn = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = event => {
    switch (event.target.name) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
    }
  };

  const handleSignIn = () => {
    props.login(email, password);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.header}>
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
            value={email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            onChange={handleChange}
            value={password}
          />
          {props.loginFailed ? <Alert className={classes.errorMessage} severity={"error"}><AlertTitle>{"Error"}</AlertTitle>{props.loginError}</Alert> : null}
          {props.loginRequested ? <Progress /> :
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSignIn}
          >
            Ingresar
          </Button>}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

const mapStateToProps = state => ({
  loginRequested: state.authentication.loginRequested,
  loginFailed: state.authentication.loginFailed,
  loginError: state.authentication.loginError,
});

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));