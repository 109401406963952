import React from 'react';
import { Router } from 'react-router-dom';
import history from './helpers/history';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import Routes from './Routes';
import store from './store';
import { GA_TRACKING_ID } from './constants/ga';

ReactGA.initialize(GA_TRACKING_ID);

export default function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  );
}
