import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import history from '../../helpers/history';
import { ErrorAlert, Progress, Snack } from '../../components';
import { password as apiPassword } from '../../api/authentication';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      marginRight: theme.spacing(2)
    },
    cancel: {
        margin: theme.spacing(3, 0, 2),
    },
    role: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }
  }));

const Account = () => {

    const classes = useStyles();

    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [snackMessage, setSnackMessage] = useState(null);

    const handleChange = (name, value) => {
        switch (name) {
          case "currentPassword":
            setCurrentPassword(value);
            break;
          case "password":
            setPassword(value);
            break;
          case "confirmPassword":
            setConfirmPassword(value);
            break;
        }
    }

    const goTo = (url) => {
        history.push(url);
    }

    const isFormValid = () => {
        return currentPassword.length > 0
            && password.length > 0
            && confirmPassword.length > 0
            && password === confirmPassword;
    }

    const onSave = async () => {
        setLoading(true);
        setErrors(null);
        const response = await apiPassword(currentPassword, password);
        if (response.status === 200) {
            setSnackMessage("Se ha cambiado la contraseña correctamente.");
        }
        else {
            setErrors(response.data);
        }
        setLoading(false);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            {loading && <Progress />}
            {errors && <ErrorAlert errors={errors} />}
            <Paper>
            <div className={classes.paper}>
                <form className={classes.form} noValidate autoComplete="off">
                    <Typography variant="h6" component="h6">Cambiar contraseña</Typography>
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="currentPassword"
                        type="password"
                        id="password"
                        label="Contraseña actual"
                        name="currentPassword"
                        helperText=""
                        value={currentPassword}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="password"
                        label="Nueva contraseña"
                        name="password"
                        helperText=""
                        value={password}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <TextField
                        error={false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="password"
                        id="confirmPassword"
                        label="Confirme contraseña"
                        name="confirmPassword"
                        helperText=""
                        value={confirmPassword}
                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                    />
                    <Button
                        onClick={() => onSave()}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!isFormValid()}
                    >
                        Guardar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => goTo("/")}
                        color="error"
                        className={classes.submit}
                    >
                        Cancelar
                    </Button>
                </form>
            </div>
            </Paper>
            <Snack
                open={snackMessage ? true : false}
                message={snackMessage}
                onClose={() => setSnackMessage(null)}
            />
        </Container>
    )
}

export default Account;
