import {
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    STORAGE_USER
  } from '../constants/authentication';
  
  const user = localStorage.getItem(STORAGE_USER) ? JSON.parse(localStorage.getItem(STORAGE_USER)) : null;
  const initialState = user ? { loggedIn: true, id: user.id, email: user.email, roleId: user.roleId } : { loggedIn: false };
  
  export default function authentication(state = initialState, action) {
    switch (action.type) {
      case LOGIN_REQUEST:
          return {
              loginRequested: true,
              loginFailed: false
          };
      case LOGIN_SUCCESS:
        return {
            loggedIn: true,
            loginRequested: false,
            id: action.payload.id,
            email: action.payload.email,
            roleId: action.payload.roleId
        };
      case LOGIN_FAILURE:
        return {
          loginRequested: false,
          loggedIn: false,
          loginFailed: true,
          loginError: action.payload,
        };
      case LOGOUT:
        return {
          loggedIn: false,
        };
      default:
        return state;
    }
  }