import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const ErrorAlert = props => {
    const errors = Array.isArray(props.errors) ? props.errors : [props.errors];
    return <Alert severity={"error"}><AlertTitle>{"Error"}</AlertTitle>
        {errors.map(error => (
            <div>{error}</div>
        ))}
    </Alert>
}

export default ErrorAlert;