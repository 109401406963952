import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { report as apiReport } from '../../api/report';
import { ErrorAlert, Progress } from '../../components';
import history from '../../helpers/history';
import Report from './Report';
import ReactGA from 'react-ga';

const View = (props) => {
    
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState(null);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        const getReport = async () => {
            const response = await apiReport(id);
            if (response.status === 200) {
                
              setReport(response.data);
                
              ReactGA.set({ dimension1: props.email });
              ReactGA.set({ dimension2: response.data.title });
              ReactGA.pageview(history.location.pathname + history.location.search);
            }
            else {
                setErrors(response.data);
            }
            setLoading(false);
        }
        getReport();
    }, [id]);

    if (loading)
        return <Progress />
    if (errors)
        return <ErrorAlert errors={errors} />
    if (report)
      return <Report report={report} />
    return null;
}

const mapStateToProps = state => ({
  email: state.authentication.email
});

export default connect(mapStateToProps)(View);
